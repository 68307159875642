import axios from 'axios';

var init = function() {
  var alertNav = document.getElementById("alertNavLink");
  var alertNavMobile = document.getElementById("alertNavLinkMobile");
  var showHits = function(hits) {
    if (alertNav) {
      alertNav.appendChild(createNewHitsSpan(hits));
    }
    if (alertNavMobile) {
      alertNavMobile.appendChild(createNewHitsSpan(hits));
    }
  };
  var createNewHitsSpan = function(globalNewHits) {
    var span = document.createElement("span");
    span.classList.add("badge-counter");
    span.appendChild(document.createTextNode(globalNewHits));
    return span;
  };
  /*  Add batch with new global alert hits */
  axios
    .get("/api/alerts/global-new-hits")
    .then(function(globalNewHitsMessage) {
      var globalNewHits = globalNewHitsMessage.data;
      if (Number(globalNewHits) > 0) {
        // view maximum 99+
        globalNewHits = Number(globalNewHits) > 99 ? "99+" : globalNewHits;
        showHits(globalNewHits);
      }
    })
    .catch(function(error) {
      console.log(error);
    });
};
window.addEventListener("load", init, false);

document.addEventListener("DOMContentLoaded", function() {
  // Get all "navbar-burger" elements
  var $navbarBurgers = Array.prototype.slice.call(
    document.querySelectorAll(".navbar-burger"),
    0
  );

  // Check if there are any navbar burgers
  if ($navbarBurgers.length > 0) {
    // Add a click event on each of them
    $navbarBurgers.forEach(function(el) {
      el.addEventListener("click", function() {
        // Get the target from the "data-target" attribute
        var target = el.dataset.target;
        var $target = document.getElementById(target);

        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        el.classList.toggle("is-active");
        $target.classList.toggle("is-active");
      });
    });
  }

  var dropdowns = document.querySelectorAll(
    ".navbar .navbar-menu .navbar-item.has-dropdown"
  );
  // IE compatible way for using forEach on a NodeList
  // (see https://developer.mozilla.org/en-US/docs/Web/API/NodeList)
  Array.prototype.forEach.call(dropdowns, function(dropdown) {
    var toggleLink = dropdown.querySelector(".navbar-link");
    toggleLink.addEventListener("click", function(event) {
      event.preventDefault();
      dropdown.classList.toggle("is-active");
    });
  });
});
